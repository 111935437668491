<script setup lang="ts">
import TheHeader from "~/components/page/TheHeader.vue";
import TheFooter from "~/components/page/TheFooter.vue";

import { successModalKey } from "~/symbols";
import { SuccessModalTypes } from "~/types/entities";

const FACILITIES_PAGE = "facilities";

const route = useRoute();
const localePath = useLocalePath();
const { cookiePolicyKey } = useRuntimeConfig().public;

const isShowCookieBlock = ref(false);
const isShowSuccessModal = ref(false);
const successModalType = ref(SuccessModalTypes.CONNECT_COMPANY);
function showSuccessModal(type: SuccessModalTypes): void {
  successModalType.value = type;
  isShowSuccessModal.value = true;
}
provide(successModalKey, showSuccessModal);

const isShowFooter = computed(() => route.path !== localePath(FACILITIES_PAGE));

const mainClass = computed(() => {
  return {
    "main-padding-bottom": !route.fullPath.includes(FACILITIES_PAGE),
  };
});

onMounted(() => {
  if (!getCookie(cookiePolicyKey)) {
    isShowCookieBlock.value = true;
  }
});
</script>

<template>
  <div id="defaultView" class="main-bg">
    <div id="anchor-top"></div>
    <TheHeader />
    <main class="main" :class="mainClass">
      <slot />
    </main>
    <TheFooter v-if="isShowFooter" />
    <LazyModalsSuccessModal
      v-if="isShowSuccessModal"
      v-model:is-show-modal="isShowSuccessModal"
      :modal-type="successModalType"
    />
    <LazyCookieBlock v-if="isShowCookieBlock" v-model:is-show-modal="isShowCookieBlock" />
  </div>
</template>
